import CommonUtil from "src/commonUtil";

const API_URL = 'https://dev2.myleadingcampus.com/api'
// const API_URL = 'https://prod1.myleadingcampus.com/api'



const apiService = async (obj) => {
    let settings = {
        method: obj.method,
        credentials: 'include',
        headers: {
            'Content-Type': obj.content_type ? obj.content_type : 'application/json',
            "module_id": "",
            "passaccess": true,
            "authorization": "Bearer " + CommonUtil.getLocalStorage("access_token"),
        },
    }
    if(obj?.data){
        settings.body = JSON.stringify(obj.data)
    }
    if(obj?.params && Object.keys(obj.params).length > 0){
        obj.url += "?"
        for (const [key, value] of Object.entries(obj.params)) {
            obj.url += `${key}=${value}&`
        }
        obj.url = obj.url.slice(0, -1)
    }
    try {
        const response = await fetch(`${API_URL}${obj.url}`, settings);
        let result = await response.json();
        return { status: response.status, data: result };
    } catch (error) {
        console.error('API request error:', error);
        throw error;
    }
};

export default apiService;
