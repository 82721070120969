import apiService from "./apiService";

export const getMonthsAPI = (schoolId) => {
    return apiService({
        url: `/tag/school/${schoolId}`,
        method: 'GET',
    });
}

export const getDaysAPI = () => {
    return apiService({
        url: `/day`,
        method: 'GET',
    });
}

export const getFileAPI = (fileName) => {
    return apiService({
        url: `/file/${fileName}`,
        method: 'GET',
    });
}