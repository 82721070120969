import moment from "moment";

function get_all_dates(year, month) {

  let date = new Date(year, month, 1);

  let dates = [];

  let i = 0;

  while (date.getMonth() === month) {

    dates.push(new Date(date));

    date.setDate(date.getDate() + 1);

    i = i + 1;

  }


  return dates;

}

const generateMonthData = () => {
  const array = [];
  for (let d = 1; d <= 30; d++) {
    array.push({
      "id": "NDEyYzA5NGJhNjUwOGJiZDMwOWFlM2ViZTRmNjMzOWM=",
      "studentId": "NzljZTAwODYxMzU0ODY4Yjg1MjYyOGI4MGYyYzJkMTY=",
      "classId": "ZjRiYjc3YWQyZjBmZDBiNDA5NmExYjNhODk3ZjNlNjA=",
      "day": d,
      "section": "A",
      "attendanceDate": `2023-08-${d}`,
      "isPresent": true,
      "createdAt": "2023-08-01T08:25:38.000Z",
      "updatedAt": "2023-08-01T08:25:38.000Z"
    });
  }
  // console.log(array);
  return array;
}

const attendanceData = [
  {
    "id": "NzljZTAwODYxMzU0ODY4Yjg1MjYyOGI4MGYyYzJkMTY=",
    "classId": "ZjRiYjc3YWQyZjBmZDBiNDA5NmExYjNhODk3ZjNlNjA=",
    "schoolId": "ZjRiYjc3YWQyZjBmZDBiNDA5NmExYjNhODk3ZjNlNjA=",
    "firstName": "Rahul Sarkar",
    "lastName": null,
    "email": "rahul@gmail.com",
    "phone": "7003032356",
    "dob": "2017-01-01T00:00:00.000Z",
    "section": "A",
    "rollNumber": 29,
    "address": null,
    "pin": 123456,
    "city": "KOLKATA",
    "state": 0,
    "country": 1,
    "lastSchoolName": null,
    "lastExamName": null,
    "lastRollNumber": null,
    "lastMarks": null,
    "lastBoardName": null,
    "lastYear": null,
    "fatherName": "Dhiraj Kumar Shaw",
    "fatherQualification": null,
    "fatherOrganization": null,
    "fatherDesignation": null,
    "fatherAnnualIncome": null,
    "fatherAadharCard": null,
    "fatherOfficialAddress": null,
    "fatherEmail": null,
    "fatherPhone": null,
    "motherName": null,
    "motherQualification": null,
    "motherOrganization": null,
    "motherDesignation": null,
    "motherAnnualIncome": null,
    "motherAadharCard": null,
    "motherOfficialAddress": null,
    "motherEmail": null,
    "motherPhone": null,
    "registrationNumber": "ANMS230002",
    "aadharNumber": null,
    "gender": 1,
    "isNew": false,
    "oneChild": true,
    "category": 1,
    "caste": null,
    "nationality": 1,
    "religion": null,
    "admissionForm": null,
    "birthCertificate": null,
    "motherIdCard": null,
    "fatherIdCard": null,
    "studentProfilePic": null,
    "isBlackListed": null,
    "createdAt": "2023-07-22T09:32:39.000Z",
    "updatedAt": "2023-07-27T09:25:05.000Z",
    "Attendances": generateMonthData(),
    "Class": {
      "id": "ZjRiYjc3YWQyZjBmZDBiNDA5NmExYjNhODk3ZjNlNjA=",
      "className": "5"
    }
  }
]



const schoolId = "0a6e98a9-b653-4488-8d96-60988bda9036"


function getBusinessName() {
  let bname = sessionStorage.getItem("businessName");
  if (bname) {
    return bname;
  }
}

function jwtDecode(t) {
  let token = {};
  token.payload = JSON.parse(window.atob(t.split('.')[1]));
  return (token.payload)
}

function checkIfValidEmail(email) {
  return /(.+)@(.+){2,}\.(.+){2,}/.test(email);
}

function checkIfStaging() {
  return window.location.href.indexOf('localhost') > -1;
}

function setStorage(key, value) {
  sessionStorage.setItem(key, value);
}

function getStorage(key) {
  return sessionStorage.getItem(key);
}

function setLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

function getLocalStorage(key) {
  return localStorage.getItem(key);
}


function getSelectionStart(o) {
  if (o.createTextRange) {
    var r = document.selection.createRange().duplicate()
    r.moveEnd('character', o.value.length)
    if (r.text === '') return o.value.length
    return o.value.lastIndexOf(r.text)
  } else return o.selectionStart
}

function validateDecimalValue(evt) {
  var el = evt.target;
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  var number = el.value.split('.');
  if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  //just one dot (thanks ddlab)
  if (number.length > 1 && charCode === 46) {
    return false;
  }
  //get the carat position
  var caratPos = this.getSelectionStart(el);
  var dotPos = el.value.indexOf(".");
  if (caratPos > dotPos && dotPos > -1 && (number[1].length > 1)) {
    return false;
  }
  return true;
}

function setJsonLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

function getJsonLocalStorage(key) {
  var data = localStorage.getItem(key);
  if (data) {
    // console.log(data);
    return JSON.parse(data);
  } else {
    return {};
  }
}

function getSelectedSchool() {
  var data = localStorage.getItem('selectedSchool');
  if (data) {
    return JSON.parse(data)['id'];
  } else {
    return '-';
  }
}
function getSelectedSchoolLogo() {
  var data = localStorage.getItem('selectedSchoolLogo');
  if (data) {
    // console.log(data);
    return JSON.parse(data);
  } else {
    return '-';
  }
}

const getDayName = (dateString) => {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const date = new Date(dateString);
  const dayOfWeekIndex = date.getDay();
  return daysOfWeek[dayOfWeekIndex];
}

function getWorkingDaysInMonth(year, month) {
  const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
  let workingDays = 0;

  for (let day = 1; day <= daysInMonth; day++) {
    const currentDate = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
    const dayOfWeek = currentDate.day();

    // Check if the day is not Saturday (6) or Sunday (0)
    if (dayOfWeek !== 6 && dayOfWeek !== 0) {
      workingDays++;
    }
  }

  return workingDays;
}

const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
}


const CommonUtil = {
  getBusinessName,
  checkIfValidEmail,
  checkIfStaging,
  setStorage,
  getStorage,

  setLocalStorage,
  getLocalStorage,

  validateDecimalValue,
  getSelectionStart,

  setJsonLocalStorage,
  getJsonLocalStorage,

  getSelectedSchool,
  getSelectedSchoolLogo,
  jwtDecode,

  attendanceData,
  generateMonthData,
  get_all_dates,
  schoolId,
  getDayName,
  getWorkingDaysInMonth,
  formatDate
}

export default CommonUtil;