import React, { Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useColorModes } from '@coreui/react-pro'
import Loader from './components/Loader'
import './scss/style.scss'
import { Toaster } from 'react-hot-toast'
import { getMonthsAPI } from './api/commonService'
import CommonUtil from './commonUtil'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./pages/login/Login'))
const Register = React.lazy(() => import('./pages/register/Register'))
const Page404 = React.lazy(() => import('./pages/page404/Page404'))
const Page500 = React.lazy(() => import('./pages/page500/Page500'))

export const AppContext = React.createContext()


const App = () => {
  const [months, setMonths] = React.useState([])

  const getMonths = async (schoolId) => {
    try {
      const resp = await getMonthsAPI(schoolId || CommonUtil.getJsonLocalStorage("userData")?.schoolId)
      if (resp?.status === 200) {
        setMonths(resp?.data)
      } else {
        // Alert.alert(resp?.data?.message)
        throw new Error(resp?.data?.message)
      }
    } catch (error) {
      console.log(error)
    }
}


  const currentUrl = window.location.pathname

  useEffect(() => {
    let isLoggedIn = localStorage.getItem('access_token');
    if (!isLoggedIn && !["/", "/login"].includes(currentUrl)) {
      window.location.href = "/";
    } else if (isLoggedIn && ['/login', "/"].includes(currentUrl)) {
      window.location.href = "/dashboard";
    }
  }, [currentUrl])




  return (
    <AppContext.Provider value={{ months, getMonths }}>
    <BrowserRouter>
      <Suspense
        fallback={
          <div style={{
            height: '100vh',
          }}>
            <Loader />
          </div>
        }
      >
        <Routes>
          <Route exact path="/" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
      <Toaster />
    </BrowserRouter>
    </AppContext.Provider>
  )
}

export default App
